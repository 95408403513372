<template>
  <div class="settings">
    <div class="settings-content">
      <h1>{{ t("settings.settings") }}</h1>

      <div class="component">
        <h2>{{ t("settings.avatar") }}</h2>

        <InputAvatar ref="inputAvatar" />
      </div>

      <div class="component">
        <h2>{{ t("settings.general_info") }}</h2>
        <InputUsername ref="inputUsername" />
      </div>

      <div class="component">
        <h2 id="settings-phone-numbers-list">
          {{ t("settings.phone_numbers") }}
        </h2>
        <ListPhoneNumbers
          ref="listPhoneNumbers"
          ariaLabelledBy="settings-phone-numbers-list"
        />
      </div>

      <ModalDeleteAccount />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import InputAvatar from "../components/InputAvatar.vue";
import InputUsername from "../components/InputUsername.vue";
import ListPhoneNumbers from "../components/ListPhoneNumbers.vue";
import ModalDeleteAccount from "../components/ModalDeleteAccount.vue";

export default {
  name: "Settings",
  components: {
    InputAvatar,
    InputUsername,
    ListPhoneNumbers,
    ModalDeleteAccount,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t("settings.title"),
    });

    const dataLoaded = ref(false);

    const loadData = async () => {
      dataLoaded.value = true;
    };

    return {
      t,
      dataLoaded,
      loadData,
    };
  },
  watch: {
    async dataLoaded(v) {
      if (!v) {
        return;
      }

      await this.$refs.inputAvatar.dataLoaded();
      await this.$refs.inputUsername.dataLoaded();
      await this.$refs.listPhoneNumbers.dataLoaded();
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: start;
  font-size: 20px;
  margin: 20px 0;
}

h2 {
  text-align: start;
  font-size: 25px;
}

.settings {
  margin: auto;
  max-width: 600px;
}

.settings-content {
  margin: 15px;
}

.component {
  margin: 30px 0;
}
</style>
