<template>
  <ul
    v-if="phoneNumbers && phoneNumbers.length != 0"
    class="phone-numbers"
    :aria-labelledby="ariaLabelledBy"
  >
    <li :key="number.id" v-for="number in phoneNumbers" class="number">
      <span>{{ formatPhoneNum(number.countryCode, number.phone) }}</span>
      <span class="badge" v-if="number.verified == false">
        {{ t("list_phone_numbers.phone_not_verified") }}
      </span>
      <button
        :title="
          t('list_phone_numbers.delete_number', {
            num: formatPhoneNum(number.countryCode, number.phone),
          })
        "
        @click="deletePhone(number.id, number.countryCode, number.phone)"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </li>
  </ul>
  <div v-else>
    <p>{{ t("list_phone_numbers.no_phone_numbers") }}</p>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { format as phoneFormat } from "phone-fns";

export default {
  name: "ListPhoneNumbers",
  props: {
    ariaLabelledBy: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
      phoneNumbers: ref([]),
    };
  },
  methods: {
    formatPhoneNum(code, num) {
      return code + " " + phoneFormat("(NNN) NNN-NN-NN", num);
    },
    dataLoaded() {
      if (this.$store.state.user.phones != undefined) {
        this.phoneNumbers = this.$store.state.user.phones;
      }
    },
    deletePhone(id, code, number) {
      var confirmed = confirm(
        this.t("list_phone_numbers.really_delete_number", {
          number: this.formatPhoneNum(code, number),
        })
      );
      if (confirmed) {
        this.$store.dispatch("user/deletePhone", { id });
      }
    },
  },
};
</script>

<style scoped>
p {
  text-align: start;
}

.badge {
  font-size: 10px;
  background: #5c5c5c;
  padding: 5px;
  color: white;
  border-radius: 5px;
  margin: auto 10px;
}

.phone-numbers {
  list-style: none;
  text-align: start;
  margin: 0;
  padding: 0;
}

.number {
  padding: 10px;
  color: black;
  background: white;
  border: solid;
  border-width: 1px;
  border-color: #c7c7c7;
  margin: 5px 0;
}

.number span {
  margin-left: 10px;
}

.number button {
  float: right;
  border: none;
  background: none;
  font-size: 16px;
}
</style>
