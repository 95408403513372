<template>
  <div class="user-avatar-wrapper">
    <div class="img-circle user-avatar" :style="formStyle">
      <img ref="avatarPreview" :aria-label="t('input_avatar.current_avatar')" />
    </div>

    <div class="loader-class" v-if="isLoading">
      <div class="loader">
        <TheLoader />
      </div>
    </div>

    <div class="user-avatar-input" :style="formStyle">
      <form>
        <label :for="avatarInputId">
          {{ t("input_avatar.change_avatar") }}
        </label>

        <input
          type="file"
          accept="image/x-png,image/jpeg"
          :id="avatarInputId"
          ref="avatarInput"
          @change="avatarSelected"
        />

        <div v-if="errorText" role="alert" class="error-alert">
          <i class="fas fa-exclamation-circle"></i>
          {{ errorText }}
        </div>

        <button
          v-if="Boolean(selectedAvatar)"
          class="af-button"
          @click="applyClicked"
        >
          {{ t("input_avatar.apply") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

import TheLoader from "modules/ui/TheLoader";

/** Максимальный размер файла - 5 мб */
const MAX_FILESIZE = 5242880;

export default {
  name: "InputAvatar",
  components: {
    TheLoader,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      selectedAvatar: ref(null),
      avatarInputId: "avatar_" + uuidv4(),
      errorText: ref(""),
      isLoading: ref(false),
    };
  },
  watch: {
    selectedAvatar(av) {
      if (!av) {
        this.$refs.avatarPreview.src = this.existingAvatarThumb();
        this.$refs.avatarInput.value = "";
        return;
      }

      var img = this.$refs.avatarPreview;
      {
        img.src = URL.createObjectURL(av);
        img.onload = function() {
          URL.revokeObjectURL(this.src);
        };
      }
    },
  },
  computed: {
    formStyle() {
      if (this.isLoading) {
        return {
          "pointer-events": "none",
          opacity: "0.4",
        };
      }

      return {};
    },
  },
  methods: {
    ...mapGetters({ existingAvatarThumb: "user/avatarThumb" }),

    async avatarSelected(event) {
      var file = event.target.files[0];

      if (!file) {
        this.errorText = this.t("input_avatar.errors.no_file");
        return;
      }

      if (file.size > MAX_FILESIZE) {
        this.errorText = this.t("input_avatar.errors.too_large");
        return;
      }

      if (
        !(
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg") ||
          file.name.endsWith(".png")
        )
      ) {
        this.errorText = this.t("input_avatar.errors.not_an_image");
        return;
      }

      this.selectedAvatar = file;
      this.errorText = "";
    },

    async applyClicked(e) {
      e.preventDefault();

      this.isLoading = true;

      try {
        var res = await this.$store.dispatch("user/changeAvatar", {
          fileInput: this.selectedAvatar,
        });
      } catch (e) {
        console.error("Error uploading avatar:", e);
        this.errorText = this.t("input_avatar.errors.server_error");
        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      if (res.error) {
        switch (res.error) {
          case "TOO_LARGE": {
            this.errorText = this.t("input_avatar.errors.too_large");
            return;
          }
          case "BAD_GATEWAY": {
            this.errorText = this.t("input_avatar.errors.server_error");
            return;
          }
          case "LIMIT_EXCEEDED": {
            this.errorText = this.t("input_avatar.errors.limit_exceeded");
            return;
          }
          case "NO_FILE": {
            this.errorText = this.t("input_avatar.errors.no_file");
            return;
          }
          case "UNKNOWN":
          default: {
            this.errorText = this.t("input_avatar.errors.unknown");
            return;
          }
        }
      }

      this.selectedAvatar = null;
    },

    dataLoaded() {
      this.$refs.avatarPreview.src = this.existingAvatarThumb();
    },
  },
};
</script>

<style scoped>
div {
  text-align: start;
}

input {
  border: none;
  margin-bottom: 5px;
}

label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.loader-class {
  position: relative;
  width: 0;
}

.loader {
  text-align: center;
  margin: auto;
  position: absolute;
}

.user-avatar-wrapper {
  display: flex;
}

.user-avatar {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.user-avatar > img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 80px;
  min-height: 80px;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  object-fit: cover;
}

.user-avatar-input {
  margin-left: 28px;
}

.error-alert {
  background: #ee0c0c;
  color: white;
  padding: 5px 8px;
  margin: 10px 0;
  width: 100%;
}
</style>
