<template>
  <modal-generic ref="modal" openBtnClass="open-modal-btn">
    <template v-slot:open-btn>
      {{ t("modal_delete_account.delete_account") }}
    </template>

    <template v-slot:title>
      <h1>{{ t("modal_delete_account.sorry_to_see_you_go") }}</h1>
    </template>

    <template v-slot:content>
      <div>
        <label :for="deleteReasonId">
          {{ t("modal_delete_account.why") }}
        </label>
        <textarea
          name=""
          :id="deleteReasonId"
          :placeholder="t('modal_delete_account.why_reason')"
          v-model="deleteReasonText"
        ></textarea>
        <p class="symbol-count" :style="symbolCountStyle">
          {{ Number(deleteReasonText.length).toLocaleString() }} /
          {{ Number(MAX_DELETE_REASON_CHARS).toLocaleString() }}
        </p>

        <p class="delete-4ever-warn">
          <i
            class="fas fa-exclamation-triangle"
            :aria-label="t('modal_delete_account.important')"
          ></i>
          {{ t("modal_delete_account.cannot_register_again") }}
        </p>

        <button class="af-button close-btn" @click="closeModal">
          {{ t("modal_delete_account.cancel") }}
        </button>

        <button class="delete-btn" @click="deleteModal">
          {{ t("modal_delete_account.delete") }}
        </button>
      </div>
    </template>
  </modal-generic>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

import ModalGeneric from "src/components/ModalGeneric.vue";

const MAX_DELETE_REASON_CHARS = 10000;

export default {
  name: "ModalDeleteAccount",
  components: {
    ModalGeneric,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      deleteReasonId: "reason_" + uuidv4(),
      deleteReasonText: ref(""),
      MAX_DELETE_REASON_CHARS,
    };
  },
  watch: {
    deleteReasonText(new_v, old_v) {
      if (String(new_v) == String(old_v)) {
        return;
      }

      if (new_v.length >= MAX_DELETE_REASON_CHARS) {
        this.deleteReasonText = new_v.substr(0, 10000);
      }
    },
  },
  computed: {
    symbolCountStyle() {
      var len = this.deleteReasonText.length;

      if (len >= MAX_DELETE_REASON_CHARS - 100) {
        return { visibility: "visible", fontWeight: "bold" };
      }

      if (len >= MAX_DELETE_REASON_CHARS / 2) {
        return { visibility: "visible" };
      }

      return { visibility: "hidden" };
    },
  },
  methods: {
    closeModal() {
      this.$refs.modal.hideModalClicked();
    },
    async deleteModal() {
      await this.$store.dispatch("user/deleteAccount", {
        reason: this.deleteReasonText.trim(),
      });
      this.closeModal();
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 25px;
}

textarea {
  resize: none;
  width: 100%;
  height: 160px;
  margin-bottom: 4px;
}

label {
  margin-bottom: 10px;
}

.open-modal-btn {
  margin: 20px;
  border: none;
  background: none;
  color: red;
  border: none;
  background: none;
}

.symbol-count {
  width: 100%;
  text-align: end;
}

.delete-4ever-warn {
  color: red;
  font-weight: bold;
  margin-bottom: 30px;
}

.delete-4ever-warn i {
  margin: 0 5px;
}

.delete-btn {
  margin: 0 15px;
  border: none;
  background: none;
}

@media (max-width: 400px) {
  .delete-btn {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }

  .close-btn {
    width: 100%;
    margin: 0;
  }
}
</style>
