<template>
  <div class="username-input">
    <label :for="usernameInputId">
      {{ t("input_username.username") }}
    </label>
    <div class="input-wrap">
      <input
        type="text"
        :id="usernameInputId"
        v-model="username"
        @keydown.enter="applyUsername"
      />
      <div class="apply-btn-wrap">
        <transition name="fade">
          <button
            v-show="canApply"
            class="apply-btn"
            :aria-label="t('input_username.apply')"
            @click="applyUsername"
          >
            <i class="fas fa-check-circle"></i>
          </button>
        </transition>
      </div>
    </div>

    <transition name="fade">
      <div v-if="error" role="alert" class="error">
        <i v-if="errorIsBug" class="fas fa-bug"></i>
        <i v-else class="fas fa-exclamation-circle"></i>

        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "InputUsername",
  setup() {
    const { t } = useI18n();

    return {
      t,
      usernameInputId: "username_" + uuidv4(),
      username: ref(""),
      error: ref(null),
      errorIsBug: ref(false),
    };
  },
  computed: {
    usernameNotEmpty() {
      return /^\s*\S+\s*/.test(this.username);
    },
    canApply() {
      return (
        this.username != this.$store.state.user.username &&
        this.usernameNotEmpty
      );
    },
  },
  methods: {
    dataLoaded() {
      if (this.$store.state.user.username != undefined) {
        this.username = this.$store.state.user.username;
      }
    },
    async applyUsername() {
      var r = await this.$store.dispatch("user/changeUsername", {
        username: this.username.trim(),
      });

      switch (r.status) {
        case "TOO_LONG": {
          this.errorIsBug = false;
          this.error = this.t("input_username.err_too_long");
          break;
        }
        case "TOO_SHORT": {
          this.errorIsBug = false;
          this.error = this.t("input_username.err_too_short");
          break;
        }
        case "SUCCESS": {
          this.errorIsBug = false;
          this.error = null;
          break;
        }
        default: {
          this.errorIsBug = true;
          this.error = this.t("input_username.err_unknown_status");
          break;
        }
      }

      this.username = this.username.trim();
    },
  },
};
</script>

<style scoped>
.username-input {
  text-align: start;
}

.input-wrap {
  display: flex;
}

.input-wrap input {
  padding: 10px;
  margin: 0;
}

.apply-btn-wrap {
  width: 32px;
  margin-left: 5px;
}

.apply-btn {
  background: none;
  border: none;
  padding: 5px;
  margin: auto;
  font-size: 20px;
  max-width: 32px;
}

.error {
  color: red;
  margin-top: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 100;
}
</style>
